import { get } from 'lodash';
import pick from 'lodash/pick';
// import { USE_SAVED_CARD, STRIPE_PI_USER_ACTIONS_DONE_STATUSES } from './CheckoutPage';
import moment from 'moment';
import config from '../../config';
import {
  addMarketplaceEntities,
  addMarketplaceEstimatedTx,
} from '../../ducks/marketplaceData.duck';
import {
  fetchCurrentUser,
  fetchCurrentUserHasOrdersSuccess,
  updateMastercardPromoStatus,
} from '../../ducks/user.duck';
import {
  currentUserIsYoungDriver,
  denormalisedResponseEntities,
  ensureListing,
  ensurePricing,
  ensureStripeCustomer,
  listingIsCommercial,
} from '../../util/data';
import { calculateDayOfBooking, displayDateForUser } from '../../util/dates';
import { storableError } from '../../util/errors';
import { sendGAEvent } from '../../util/googleAnalytics';
import { checkoutCredits, checkoutPromos, simulateCheckoutPromos } from '../../util/lightrail';
import * as log from '../../util/log';
import { sendNotification } from '../../util/notification';
import { types as sdkTypes } from '../../util/sdkLoader';
import { sendTransactionMsgContainingPhoneNoOrEmailNoti } from '../../util/slackNotify';
import {
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT,
  TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED,
  TRANSITION_CONFIRM_PAYMENT_UNVERIFIED,
  TRANSITION_DEPOSIT_CONFIRM_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE,
  TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL,
  TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE,
  TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF,
  TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
  TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT,
} from '../../util/transaction';
import {
  PROCESS_NAME_LTR_FIRST,
  TRANSITION_LTF_CONFIRM_PAYMENT,
} from '../../util/transactionLongTermFirst';
import {
  PROCESS_NAME_LTR_LAST,
  TRANSITION_LTL_CONFIRM_PAYMENT,
  TRANSITION_LTL_CREATE_PAYMENT_INTENT,
} from '../../util/transactionLongTermLast';
import {
  PROCESS_NAME_LTR_MIDDLE,
  TRANSITION_LTM_CONFIRM_PAYMENT,
  TRANSITION_LTM_CREATE_PAYMENT_INTENT,
  TRANSITION_TYPE_LTM_CONFIRM_PAYMENT,
} from '../../util/transactionLongTermMiddle';
import {
  ERROR_CODE_INVALID_VOUCHER,
  LINE_ITEM_CUSTOMER_PROMO,
  LINE_ITEM_MASTERCARD_PROMO,
  LINE_ITEM_UNITS,
} from '../../util/types';
import {
  doesMessageContainPhoneNumberOrEmail,
  encodeMsgContainingPhoneNoOrEmailMaybe,
} from '../../util/validators';
import { updateBookingRequest, updateSpeculativeRequest } from '../EditTripPage/EditTip.api';
import {
  REQUEST_STATUS__ERROR,
  REQUEST_STATUS__NULL,
  REQUEST_STATUS__PENDING,
  REQUEST_STATUS__SUCCESS,
} from '../../constants/other';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import * as selectors from '../EditTripPage/EditTripPage.selectors';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/AfterDropOffPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const SET_BOOKING_OVERLAP = 'app/CheckoutPage/SET_BOOKING_OVERLAP';

export const SET_DEPOSIT_TX = 'app/CheckoutPage/SET_DEPOSIT_TX';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateUpdateBookingRequestStatus: REQUEST_STATUS__NULL,
  initiateOrderError: null,
  confirmPaymentError: null,
  transactionRefs: [],
  stripeCustomerFetched: false,
  bookingOverlapError: null,
  timeSlotsObj: {},
  depositTx: null,
  parentTransaction: {},
  testDataFromTripPanel: null,
  rootTx: null,
};

export default function afterDropOffPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };
    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateUpdateBookingRequestStatus: REQUEST_STATUS__PENDING, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, initiateUpdateBookingRequestStatus: REQUEST_STATUS__SUCCESS, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateUpdateBookingRequestStatus: REQUEST_STATUS__ERROR, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };
    case SET_BOOKING_OVERLAP:
      return { ...state, bookingOverlapError: true };
    case SET_DEPOSIT_TX:
      return {
        ...state,
        depositTx: payload,
      };
    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

const generateUUIDv4 = () => {
  let uuid = '',
    i,
    random;
  for (i = 0; i < 32; i++) {
    random = (Math.random() * 16) | 0;

    if (i == 8 || i == 12 || i == 16 || i == 20) {
      uuid += '-';
    }
    uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
  }
  return uuid;
};

export const initiateOrder = (
  orderParams,
  transactionId,
  bookingProcess = null,
  minimum = false,
  savedListing = null,
  selectedPaymentFlow = null,
  handlePaymentParams = null
) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());
  const bookingProcessList = [
    config.bookingProcessAliasPrivate,
    config.bookingProcessAliasCommercial,
    config.bookingProcessAliasYoungPrivate,
    config.bookingProcessAliasYoungCommercial,
    config.masterProcessAlias,
    config.updateBookingChargingProcessAlias,
  ];

  const finalBookingProcess = !bookingProcess
    ? config.bookingProcessAlias
    : bookingProcessList.includes(bookingProcess)
    ? bookingProcess
    : config.bookingProcessAlias;

  const listing = getState().CheckoutPage.listing ? getState().CheckoutPage.listing : savedListing;
  const { speculatedTransaction } = getState().CheckoutPage;
  const { lineItems } = speculatedTransaction.attributes;
  if (
    lineItems.find(item => item.code === LINE_ITEM_MASTERCARD_PROMO) &&
    orderParams.shouldUseMastercardPromoLineItem
  ) {
    orderParams.protectedData.hasMastercardPromoBeenUsed = true;
  }
  const isNotLighrails = !lineItems.find(item => item.code === LINE_ITEM_CUSTOMER_PROMO);
  const { pricing } = listing.attributes.publicData;
  const ensuredPricing = ensurePricing(pricing);
  const { peakPrice = {} } = ensuredPricing;

  const unitPurchase = lineItems.find(item => item.code === LINE_ITEM_UNITS);
  const totalPurchase = unitPurchase.unitPrice.amount * unitPurchase.quantity;

  const isPrivateCar =
    listing.attributes.publicData.insurance &&
    listing.attributes.publicData.insurance === 'private';

  const { transition, ...rest } = orderParams;
  const { bookingStart, bookingEnd } = orderParams;
  const bodyParams = {
    processAlias: finalBookingProcess,
    transition: transition,
    params: {
      ...rest,
      bookingStart: moment(bookingStart).toDate(),
      bookingEnd: moment(bookingEnd).toDate(),
    },
    isSecurityDeposit: true,
    isDistanceCharge: true
  };

  if (orderParams.voucherCode) {
    bodyParams.params.protectedData = {
      ...bodyParams.params.protectedData,
      ...orderParams.voucherCode,
    };
  }

  if (listing.attributes.publicData.instantBooking) {
    bodyParams.params.protectedData = {
      ...bodyParams.params.protectedData,
      isInstantBooking: true,
    };
  }

  // if (isPrivateCar) {
  //   bodyParams.params.protectedData = {
  //     ...bodyParams.params.protectedData,
  //     // isPrivateCar: true,
  //   };
  // }

  bodyParams.params.protectedData = {
    ...bodyParams.params.protectedData,
    // days: daysOfBookings.days,
    // isPrivateCar: listing.attributes.publicData.insurance === 'private',
    pricing: {
      peakPrice: {
        amount: peakPrice && peakPrice.amount / 100,
        currency: peakPrice && peakPrice.currency,
      },
      regularPrice: {
        amount: listing.attributes.price.amount / 100,
        currency: listing.attributes.price.currency,
      },
    },
    hostWillEarn: {
      amount: totalPurchase / 100,
      currency: listing.attributes.price.currency,
    },
    ...(orderParams.isFuelIncluded ? {isFuelIncluded: orderParams.isFuelIncluded} : {}),
    // discountPercentage:
    // daysOfBookings.numberOfDays >= 30 ? month : daysOfBookings.numberOfDays >= 7 ? week : 0,
  };

  if (listing.attributes.publicData.instantBooking) {
    bodyParams.params.protectedData = {
      ...bodyParams.params.protectedData,
      isInstantBooking: true,
    };
  }

  if (isPrivateCar) {
    bodyParams.params.protectedData = {
      ...bodyParams.params.protectedData,
      isPrivateCar: true,
    };
  }

  bodyParams.params.protectedData = {
    ...bodyParams.params.protectedData,
    bookingOriginalDates: {
      start: (new Date(bodyParams.params.bookingDisplayStart)).getTime(),
      end: (new Date(bodyParams.params.bookingDisplayEnd)).getTime(),
    },
  };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  const createOrder = transactionId ? sdk.jh.transactions.transition : sdk.jh.transactions.initiate;

  let manualErorr = null;
  let order = null;

  return sdk.currentUser
    .show()
    .then(user => {
      return user;
    })
    .then(user => {
      // const emailVerificationNeeded = !user.data.data.attributes.emailVerified;
      // if (emailVerificationNeeded) {
      //   //Dissallow creating listing
      //   const error = {
      //     type: 'error',
      //     name: 'Unverified Email',
      //     message: 'Your email is not verified',
      //     status: 400,
      //     statusText: 'Bad request',
      //   };
      //   dispatch(initiateOrderError(storableError(error)));
      //   throw error;
      // }

      // const phoneVerifiedNeeded = !!user.data.data.attributes.profile.protectedData
      //   .phoneNumberVerified
      //   ? false
      //   : true;

      // if (phoneVerifiedNeeded) {
      //   //Dissallow creating listing
      //   const error = {
      //     type: 'error',
      //     name: 'Unverified Phone',
      //     message: 'Your phone is not verified',
      //     status: 400,
      //     statusText: 'Bad request',
      //   };
      //   dispatch(initiateOrderError(storableError(error)));
      //   throw error;
      // }
      if (bodyParams &&
        bodyParams.params &&
        !bodyParams.params.protectedData) {
        bodyParams.params.protectedData = {};
      }
      if (bodyParams &&
        bodyParams.params &&
        bodyParams.params.protectedData &&
        typeof bodyParams.params.protectedData === 'object') {
        // Assign values
        const userData = user && user.data && user.data.data ? user.data.data : {};
        const isReturning = get(userData, 'attributes.profile.metadata.intercomUserStat.success_booking_as_guest', 0);
        bodyParams.params.protectedData.guestVerificationStatus = get(userData, 'attributes.profile.publicData.guestIdentityVerificationStatus', 'null');
        bodyParams.params.protectedData.guestIsReturning = isReturning && isReturning > 0 ? true : false;
        // bodyParams.params.protectedData.guestMilesClub = get(userData, 'attributes.profile.publicData.premiumUserTier', 'null');
      }
      return createOrder(bodyParams, queryParams);
    })
    .then(orderData => {
      if (isNotLighrails) return orderData;

      const hasPromo = orderParams.voucherCode && orderParams.voucherCode.discount;

      const { currentUser } = getState().user;
      const bookingId = orderData.data.data.id.uuid;
      const checkoutData = hasPromo
        ? {
            id: bookingId,
            code: orderParams.voucherCode.code,
            lineItems: [
              {
                type: 'product',
                productId: listing.id.uuid,
                unitPrice: totalPurchase,
              },
            ],
          }
        : {
            id: bookingId,
            valueId: currentUser.attributes.creditItem ? currentUser.attributes.creditItem.id : '',
          };
      const { isGenericCode } = orderParams.voucherCode || {};
      // if (isGenericCode) {
        checkoutData.contactId = (currentUser &&  currentUser.id && currentUser.id.uuid || "");
      // }

      return hasPromo
        ? simulateCheckoutPromos(checkoutData)
            .then(response => {
              if (response.status > 199 && response.status < 300) {
                return response.json();
              } else {
                return { data: null };
              }
            })
            .then(processedResponse => {
              const simulateData = processedResponse.data;

              if (!simulateData || !simulateData.totals || !simulateData.totals.discount) {
                manualErorr = {
                  type: 'error',
                  name: ERROR_CODE_INVALID_VOUCHER,
                  message: ERROR_CODE_INVALID_VOUCHER,
                  status: 404,
                  statusText: ERROR_CODE_INVALID_VOUCHER,
                  apiErrors: [{ code: ERROR_CODE_INVALID_VOUCHER }],
                };
                throw manualErorr;
              } else {
                return checkoutPromos(checkoutData).then(() => orderData);
              }
            })
        : checkoutCredits(checkoutData).then(res => {
            if (res.status !== 200) {
              manualErorr = {
                type: 'error',
                name: ERROR_CODE_INVALID_VOUCHER,
                message: ERROR_CODE_INVALID_VOUCHER,
                status: 404,
                statusText: ERROR_CODE_INVALID_VOUCHER,
                apiErrors: [{ code: ERROR_CODE_INVALID_VOUCHER }],
              };
              throw manualErorr;
            } else {
              return orderData;
            }
          });
    })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      order = entities[0];

      const depositTx = get(order, 'attributes.protectedData.depositTx', null);
      if (depositTx) {
        return sdk.transactions.show({
          id: new UUID(depositTx),
          expand: true,
        });
      } else {
        return null;
      }
    })
    .then(depositResponse => {
      if (depositResponse) {
        const [depositTx] = denormalisedResponseEntities(depositResponse);
        dispatch({ type: SET_DEPOSIT_TX, payload: depositTx });
        order.deposit = depositTx;
      }

      dispatch(initiateOrderSuccess(order));
      dispatch(fetchCurrentUserHasOrdersSuccess(true));
      return order;
    })
    .catch(e => {
      const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        listingId: orderParams.listingId.uuid,
        bookingStart: orderParams.bookingStart,
        bookingEnd: orderParams.bookingEnd,
      });
      const resultError = e && e.error ? e.error : e;
      return dispatch(initiateOrderError(manualErorr ? manualErorr : storableError(resultError)));
    });
};

const getTransitionFromProcessName = (processName, publicData, isUnverified = false) => {
  let transition = publicData.instantBooking
    ? TRANSITION_CONFIRM_PAYMENT_INSTANT
    : TRANSITION_CONFIRM_PAYMENT;
  console.log("Is UNverified", isUnverified)
  if(isUnverified) {
    transition = publicData.instantBooking
    ? TRANSITION_CONFIRM_PAYMENT_INSTANT_UNVERIFIED
    : TRANSITION_CONFIRM_PAYMENT_UNVERIFIED;
  }

  if (processName === PROCESS_NAME_LTR_FIRST) {
    transition = TRANSITION_LTF_CONFIRM_PAYMENT;
  } else if (processName === PROCESS_NAME_LTR_MIDDLE) {
    transition = TRANSITION_LTM_CONFIRM_PAYMENT;
  } else if (processName === PROCESS_NAME_LTR_LAST) {
    transition = TRANSITION_LTL_CONFIRM_PAYMENT;
  }
  console.log("Transition from process name", transition,isUnverified, publicData.instantBooking )
  return transition;
};

const getPaymentIntentTransitionFromProcessName = (processName, publicData) => {
  let transition = TRANSITION_LTL_CREATE_PAYMENT_INTENT;
  if (processName === PROCESS_NAME_LTR_MIDDLE) {
    transition = TRANSITION_LTM_CREATE_PAYMENT_INTENT;
  } else if (processName === PROCESS_NAME_LTR_LAST) {
    transition = TRANSITION_LTL_CREATE_PAYMENT_INTENT;
  }
  return transition;
};

export const createPaymentIntent = params => (dispatch, getState, sdk) => {
  const transactionId = get(params, 'tx.id.uuid');
  const processName = get(params, 'tx.attributes.processName');
  const paymentOptional = params && params.paymentParams;
  const listingId = get(params, 'tx.listing.id.uuid');
  const bookingStart = get(params, 'tx.booking.attributes.start');
  const bookingDisplayStart = get(params, 'tx.booking.attributes.displayStart');
  const bookingEnd = get(params, 'tx.booking.attributes.end');
  const bookingDisplayEnd = get(params, 'tx.booking.attributes.displayEnd');
  const transition = getPaymentIntentTransitionFromProcessName(processName);
  const particularParams = {
    transitionType: TRANSITION_TYPE_LTM_CONFIRM_PAYMENT,
    transitionInitial: get(params, 'tx.attributes.transitions[0].transition'),
  };
  const bodyParams = {
    id: transactionId,
    transition,
    particularParams,
    params: {
      paymentOptional,
      listingId,
      bookingStart,
      bookingDisplayStart,
      bookingEnd,
      bookingDisplayEnd,
    },
    middleTransaction: params && params.tx,
  };

  return sdk.jh.transactions.transition(bodyParams, { expand: true }).then(response => {
    //todo: send booking request here
    const order = denormalisedResponseEntities(response)[0]; // response.data.data;
    return order;
  });
};

export const confirmPayment = ({ isPaidAmount, savedListing, order: orderFromParams, ...orderParams }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(confirmPaymentRequest());
  const { currentUser } = getState().user;
  const { stripeCustomerId } = ensureStripeCustomer(currentUser.stripeCustomer).attributes;

  const listing = getState().CheckoutPage.listing ? getState().CheckoutPage.listing : savedListing;

  const { publicData = {} } = ensureListing(listing).attributes;
  console.log("pbulic data", publicData);
  const processName = get(orderFromParams, 'attributes.processName');
  const { guestIdentityVerificationStatus } = currentUser.attributes.profile.publicData;

  let transition = getTransitionFromProcessName(processName, publicData, guestIdentityVerificationStatus !== 'confirmed' ? true: false);

  // if(guestIdentityVerificationStatus !== 'confirmed') {
  //   transition = TRANSITION_CONFIRM_PAYMENT_UNVERIFIED;
  // }

  if(isPaidAmount) {
    transition = TRANSITION_UPDATE_BOOKING_CHILD_TX_CONFIRM_PAYMENT;
  }

  console.log("Current user", guestIdentityVerificationStatus, currentUser, transition);
  console.log("Transacrtion and order params", orderParams);
  console.log("Transacrtion and tttttt", orderFromParams);


  const bodyParams = {
    id: orderParams.transactionId,
    transition,
    params: isPaidAmount ?
      {
        protectedData: {
          parentTransaction: {
            transactionId: orderParams.parentTransaction.transactionId.uuid
          }
        }
      } : {},
  };
  console.log("SDK Request Params", bodyParams);
  return sdk.transactions
    .transition(bodyParams, { expand: true })
    .then(response => {
      console.log("SDK Response", response, isPaidAmount);
      //todo: send booking request here
      const order = denormalisedResponseEntities(response)[0]; // response.data.data;
      dispatch(confirmPaymentSuccess(order.id));

      const { currentUser } = getState().user;

      if (!isPaidAmount){
        sendNotification({
          userId: currentUser.id.uuid,
          transactionId: order.id.uuid,
          transition: bodyParams.transition,
          uvk: get(currentUser, 'attributes.profile.privateData.userVerificationKey'),
        });
      }

      // sendEventGeneral({
      //   eventType: BOOKING_REQUEST_SENT,
      //   transactionUUID: order.id.uuid,
      //   startDate: new Date(order.attributes.protectedData.bookingDisplayStart),
      //   endDate: new Date(order.attributes.protectedData.bookingDisplayEnd),
      //   totalPrice: {
      //     amount: finalTotalPrice,
      //     currency: totalPrice.currency,
      //   },
      //   promoAmount: promoAmount,
      //   listingName: listing.attributes.title,
      //   hostId: listing.author.id.uuid,
      //   isInstantBooking: listing.attributes.publicData.instantBooking,
      //   guestName: currentUser.attributes.profile.displayName,
      //   isOldCar: checkIsOldCar(listing),
      //   isPrivateCar: checkIsPrivateCar(listing),
      // })
      //   .then(() => console.log('sent BOOKING_REQUEST_SENT'))
      //   .catch(console.log.error);

      sendGAEvent({
        eventCategory: 'Transaction',
        eventAction: 'Create A Booking Request',
        eventValue: 2,
      });

      // sendEventGeneral({
      //   eventType: BOOKING_REQUEST_PAYMENT_COMPLETED,
      //   userId: currentUser.id.uuid,
      //   transactionUUID: orderParams.transactionId.uuid,
      //   isInstantBooking: !!publicData.instantBooking,
      //   stripeCustomerId,
      // });

      if (orderFromParams.attributes.lineItems.find(l => l.code === LINE_ITEM_MASTERCARD_PROMO)) {
        updateMastercardPromoStatus({
          userId: orderParams.userId,
        });
      }

      if (publicData.instantBooking) {
        // captureTransaction(orderParams.transactionId.uuid).then(data => {
        //   console.log(`captured instant transaction ${orderParams.transactionId.uuid}`);
        // });
        sendGAEvent({
          eventCategory: 'Transaction',
          eventAction: 'Accept Instant Booking Request',
        });
      }
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      console.log("duck file Error", e);
      throw e;
    });
};

export const confirmPaymentDeposit = ({ order }) => (dispatch, getState, sdk) => {
  console.log("Order", order);
  const bodyParams = {
    id: order.id,
    transition: TRANSITION_DEPOSIT_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      return response;
    })
    .catch(e => {
      throw e;
    });
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    let finalMessage = message;
    const { currentUser } = getState().user;
    if (doesMessageContainPhoneNumberOrEmail(message)) {
      sendTransactionMsgContainingPhoneNoOrEmailNoti({
        transactionId: orderId.uuid,
        senderId: currentUser.id.uuid,
        fromCheckoutPage: true,
        message,
      });
      finalMessage = encodeMsgContainingPhoneNoOrEmailMaybe(message);
    }

    return sdk.messages
      .send({ transactionId: orderId, content: finalMessage })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate the speculative transaction with the given booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (
  params,
  bookingProcess = null,
  minimum = false,
  savedListing = null,
  transition
) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());
  const bookingProcessList = [
    config.bookingProcessAliasPrivate,
    config.bookingProcessAliasCommercial,
    config.bookingProcessAliasYoungPrivate,
    config.bookingProcessAliasYoungCommercial,
    config.masterProcessAlias,
    config.updateBookingChargingProcessAlias,
  ];

  const finalBookingProcess = !bookingProcess
    ? config.bookingProcessAlias
    : bookingProcessList.includes(bookingProcess)
    ? bookingProcess
    : config.bookingProcessAlias;

  const { currentBookingStart, currentBookingEnd, currentBookingDateStart, currentBookingDateEnd, customerPhoneNumber, customerLocation, isDrivelahGo, ...finalParams } = params;

  const listing = getState().CheckoutPage.listing ? getState().CheckoutPage.listing : savedListing;
  const { pricing } = listing.attributes.publicData;
  const ensuredPricing = ensurePricing(pricing);
  const { regularDays, peakDays, discount: discountPercentageObj } = ensuredPricing;
  const daysOfBookings = calculateDayOfBooking(
    params.bookingDisplayStart,
    params.bookingDisplayEnd,
    {
      regularDays,
      peakDays,
    }
  );

  const { week = 0, month = 0 } = discountPercentageObj || {};

  const bodyParams = {
    transition: transition,
    processAlias: finalBookingProcess,
    currentPage: 'CheckoutPage',
    params: {
      ...finalParams,
      cardToken: 'CheckoutPage_speculative_card_token',
      protectedData: {
        bookingDisplayStart: new Date(displayDateForUser(params.bookingDisplayStart)).getTime(),
        bookingDisplayEnd: new Date(displayDateForUser(params.bookingDisplayEnd)).getTime(),
        customerPhoneNumberObj: customerPhoneNumber,
        stripeTKey: params.stripeTKey,
        customerLocation: customerLocation,
        isDrivelahGo: isDrivelahGo,
        slickEnabled: listing.attributes.publicData.slickEnabled,
        ...params.voucherCode,
        days: daysOfBookings.days,
        discountPercentage:
          daysOfBookings.numberOfDays >= 30 ? month : daysOfBookings.numberOfDays >= 7 ? week : 0,
        payForFuel: params.payForFuel,
        VAS_ExcessReduction: params.isExcessReduction,
        currentBookingEnd,
        currentBookingStart,
        currentBookingDateEnd,
        currentBookingDateStart,
      },
    },
    isSecurityDeposit: true,
    platform: 'web',
    isDistanceCharge: true
  };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };
  console.log("SDK kskdjskdfsjkfsfj", sdk)
  return sdk.jh.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      const txRaw = entities[0];

      const tx = txRaw;
      dispatch(speculateTransactionSuccess(tx));
    })
    .catch(e => {
      const { listingId, bookingStart, bookingEnd } = params;
      log.error(e, 'speculate-transaction-failed', {
        listingId: listingId.uuid,
        bookingStart,
        bookingEnd,
      });
      return dispatch(speculateTransactionError(storableError(e)));
    });
};
export const speculateTransaction2 = (
  params
) => (dispatch, getState, sdk) => {
  console.log('fetchDistanceTravelled >> speculateTransaction2 >>', params );
  
  dispatch(speculateTransactionRequest());

  const bodyParams = {
    transition: "transition/distance-charging-request-payment",
    processAlias: "distance-charging/release-1",
    currentPage: 'AfterDropOffPage',
    params: {
      listingId: params.listingId,
      // transactionId: params.transactionId,
    },
    isSecurityDeposit: true,
    platform: 'web',
    isDistanceCharge: true,
    distanceTravelled: params.distanceTravelled,
    isDropOff: true,
  };
  console.log('fetchDistanceTravelled >> speculateTransaction2 >>', bodyParams );

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };
  console.log("SDK kskdjskdfsjkfsfj", sdk)
  return sdk.jh.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(response => {
      console.log('fetchDistanceTravelled >> speculateTransaction2 > response >>', response );
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      const txRaw = entities[0];

      const tx = txRaw;
      console.log('fetchDistanceTravelled >> speculateTransaction2 > TX >>', tx );
      dispatch(speculateTransactionSuccess(tx));
    })
    .catch(e => {
      const { listingId, bookingStart, bookingEnd } = params;
      log.error(e, 'speculate-transaction-failed', {
        listingId: listingId.uuid,
        bookingStart,
        bookingEnd,
      });
      return dispatch(speculateTransactionError(storableError(e)));
    });
};

export const fetchDataForUpdateBookingCheckoutPage = updateBookingData => (dispatch, getState, sdk) => {
  const {
    bookingTxId,
    updateStart,
    updateEnd,
  } = updateBookingData;
  dispatch(speculateTransactionRequest());
  updateSpeculativeRequest(bookingTxId, updateStart, updateEnd, sdk)
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      dispatch(speculateTransactionSuccess(entities[0]));
    })
    .catch(e => dispatch(speculateTransactionError(storableError(e))))
  ;
};

export const makeUpdateBookingRequest = (updateBookingData, history) => (dispatch, getState, sdk) => {
  const {
    bookingTxId,
    updateStart,
    updateEnd,
  } = updateBookingData;
  const state = getState();
  const bookingTx = selectors.$bookingTx(state);
  const uvk = get(state, 'user.currentUser.attributes.profile.privateData.userVerificationKey', null);

  dispatch(initiateOrderRequest());
  updateBookingRequest(bookingTxId, updateStart, updateEnd, sdk)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      const entities = denormalisedResponseEntities(response);
      const order = entities[0];
      dispatch(initiateOrderSuccess(order));
      const routes = routeConfiguration();
      const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
        id: bookingTxId,
      });

      if (order && order.attributes.lastTransition === TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF) {
        sendNotification({
          userId: bookingTx.customer.id.uuid,
          transactionId: bookingTx.id.uuid,
          transition: TRANSITION_UPDATE_BOOKING_BEFORE_DROP_OFF,
          uvk,
        });
      } else if (bookingTx) {
        sendNotification({
          userId: bookingTx.customer.id.uuid,
          transactionId: bookingTx.id.uuid,
          transition: TRANSITION_UPDATE_BOOKING_BEFORE_PICK_UP_NON_REFUNDABLE,
          uvk,
        });
      }

      history.push(orderDetailsPath);
    })
    .catch(error => dispatch(initiateOrderError(storableError(error))))
  ;
};

export const fetchTransaction = id => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };
  return sdk.transactions
    .show({ id, ...queryParams })
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.transactions.initiateSpeculative response');
      }
      const txRaw = entities[0];

      const tx = txRaw;
      dispatch(speculateTransactionSuccess(tx));
    })
    .catch(e => {
      log.error(e, 'fetch-transaction-failed', {
        id,
      });
      return dispatch(speculateTransactionError(storableError(e)));
    });
};
// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser(null, { include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

// export const checkBookingOverlap = (listingId, start, end) => dispatch => {
//   return checkOverlap({ listingId, start, end }).then(response => {
//     if (response.valid) {
//       return response;
//     } else {
//       dispatch({ type: SET_BOOKING_OVERLAP });
//       throw response;
//     }
//   });
// };

export const fetchEstimateBreakdown = params => (dispatch, getState, sdk) => {
  const {
    bookingStart,
    bookingEnd,
    listing,
    currentUser,
    discount,
    credits,
    checkedCode = {},
  } = params;

  const isYoung = currentUserIsYoungDriver(currentUser);
  const isCommercial = listingIsCommercial(listing);
  let transition = null;
  if (isYoung) {
    if (isCommercial) {
      transition = TRANSITION_REQUEST_PAYMENT_YOUNG_COMMERCIAL;
    } else {
      transition = TRANSITION_REQUEST_PAYMENT_YOUNG_PRIVATE;
    }
  } else {
    if (isCommercial) {
      transition = TRANSITION_REQUEST_PAYMENT_NORMAL_COMMERCIAL;
    } else {
      transition = TRANSITION_REQUEST_PAYMENT_NORMAL_PRIVATE;
    }
  }

  const bodyParams = {
    processAlias: config.masterProcessAlias,
    transition,
    currentPage: 'CheckoutPage',
    params: {
      bookingStart,
      bookingEnd,
      bookingDisplayStart: bookingStart,
      bookingDisplayEnd: bookingEnd,
      credits,
      voucherCode: {
        discount,
        ...checkedCode,
      },
      listingId: listing.id,
      protectedData: {
        ...checkedCode,
      },
    },
    isSecurityDeposit: true,
    platform: 'web',
    isDistanceCharge: true
  };

  const queryParams = {
    include: ['booking', 'provider', 'listing'],
    expand: true,
  };

  console.log("SDK query params", sdk);
  return sdk.jh.transactions
    .initiateSpeculative(bodyParams, queryParams)
    .then(response => {
      const [tx] = denormalisedResponseEntities(response);
      dispatch(addMarketplaceEstimatedTx(tx));
      return tx;
    })
    .catch(e => {
      console.log(e);
    });
};

export const fetchDepositTx = ({ id }) => (dispatch, _getState, sdk) => {
  return sdk.transactions
    .show({
      id: id,
    })
    .then(response => {
      const deposit = denormalisedResponseEntities(response)[0];
      dispatch({
        type: SET_DEPOSIT_TX,
        payload: deposit,
      });
      return deposit;
    });
};
