import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import {
  LINE_ITEM_INITIAL_PAYMENT,
  LINE_ITEM_PROCESSING_FEE,
  LINE_ITEM_PROCESSING_FEE_ADDONS,
  LINE_ITEM_PROTECTION_FEE,
} from '../../util/types';
import Explanation from './Explaination';
import classNames from "classnames";
import css from './BookingBreakdown.css';
import { get } from 'lodash';

const LineItemProtectionFee = ({
  transaction,
  isCustomer,
  intl,
  isAddons,
  isTripDetailsPage,
  isExplanationBreakdown,
}) => {
  const protectionFeeLineItem = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROTECTION_FEE && !item.reversal
  );

  const protectionFeeLineItemRefund = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_PROTECTION_FEE && item.reversal
  );

  const numberOfDays = get(transaction, 'attributes.protectedData.commissionObj.note.bookingDays', '')
  
  console.log('Protection Fee >> protectionFeeLineItem', protectionFeeLineItem);

  if (!isCustomer || !protectionFeeLineItem) {
    return null;
  }

  const protection = protectionFeeLineItem ? protectionFeeLineItem.lineTotal : null;

  const protectionRefund = protectionFeeLineItemRefund ? protectionFeeLineItemRefund.lineTotal : null;

  console.log('Protection Fee >> protection', protection);

  const formattedProtection = protection ? formatMoney(intl, protection, 2) : null;

  const formattedProtectionRefund = protectionRefund ? formatMoney(intl, protectionRefund, 2) : null;

  return (
    <>
    {formattedProtection ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.ProtectionFee" />
        {/* <Explanation text= {`Fee for providing comprehensive insurance ($9.99 x ${numberOfDays} ${numberOfDays > 1 ? 'days' : 'day'})`} /> */}
      </span>
      <span className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedProtection}</span>
    </div>
  ) : null}
    {formattedProtectionRefund ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.ProtectionFee" />
        {/* <Explanation text= {`Fee for providing comprehensive insurance ($9.99 x ${numberOfDays} ${numberOfDays > 1 ? 'days' : 'day'})`} /> */}
      </span>
      <span className={classNames(css.itemValue, {[css.explanationValue]: isExplanationBreakdown})}>{formattedProtectionRefund}</span>
    </div>
  ) : null}
    </>
  )
};

export default LineItemProtectionFee;
